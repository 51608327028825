import React from 'react';
import MainTemplate from '../../templates/MainTemplate';
import SubpageHeader from '../../components/organisms/SubpageHeader';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Headline from '../../components/atoms/Headline';
import Text from '../../components/atoms/Text';
import SecondGallery from '../../components/organisms/SecondGallery';

const BeOurPartnerSection = styled.section`
  margin: 100px auto;
  padding: 0 15px;
  max-width: 1200px;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 0 5%;
  }

  @media (min-width: ${({ theme }) => theme.desktop}) {
    padding: 0;
  }
`;

const BigHeadline = styled(Headline)`
  text-transform: uppercase;
  text-align: center;
  font-weight: ${({ theme }) => theme.bold};
  margin-bottom: 30px;
`;

const Content = styled.div`
  margin-bottom: 100px;

  ul {
    padding-left: 30px;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const StyledText = styled(Text)`
  margin-bottom: 15px;

  p,
  ul {
    margin-bottom: 15px;
  }
`;

const IframeContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 39.5%;
  height: 0;
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.laptop}) {
    width: 70%;
  }
`;

const ApartmentInvestProductsPage = () => {
  const imageData = useStaticQuery(query);
  return (
    <MainTemplate
      isMainPage={false}
      title='Mieszkaniowe produkty inwestycyjne'
      metaDescription='Dokonamy oceny i analizy zarówno umowy deweloperskiej, cesji umowy
    deweloperskiej jak i umowy Sprzedaży nieruchomości (gruntowej,
    budynkowej i lokalowej)'
    >
      <SubpageHeader headline='Mieszkaniowe produkty inwestycyjne' />
      <BeOurPartnerSection>
        <BigHeadline data-aos='headline-fadeup'>
          {imageData.datoCmsApartmentInvestProduct.firstAccordionTitle}
        </BigHeadline>
        <Content>
          <StyledText
            dangerouslySetInnerHTML={{
              __html:
                imageData.datoCmsApartmentInvestProduct.firstAccordionText,
            }}
          />
          {/* <IframeContainer>
            <iframe
              width='560'
              height='315'
              src={imageData.datoCmsApartmentInvestProduct.firstAccordionLink}
              title='YouTube video player'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowfullscreen
            ></iframe>
          </IframeContainer> */}
        </Content>
      </BeOurPartnerSection>
      <SecondGallery
        link={imageData.datoCmsApartmentInvestProduct.firstAccordionLink}
      />
    </MainTemplate>
  );
};

const query = graphql`
  query ApartmentInvestProductsPageQuery {
    datoCmsApartmentInvestProduct {
      firstAccordionLink
      firstAccordionText
      firstAccordionTitle
    }
  }
`;

export default ApartmentInvestProductsPage;
