import React, { useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { graphql, useStaticQuery } from 'gatsby';
import KawalerkaWKamienicy from '../../assets/images/investments/thumbnails/kawalerka-w-kamienicy.jpg';
import NowoczesnaKamienica from '../../assets/images/investments/thumbnails/nowoczesne-mieszkanie-w-kamienicy.jpg';
import Text from '../atoms/Text';
import Lightbox from 'yet-another-react-lightbox';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import Iframe from '../atoms/Iframe';

import 'swiper/css/bundle';
import 'swiper/css';
import 'swiper/css/navigation';

import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

const Wrapper = styled.section`
  margin: 100px 0;
  /* padding: 0 15px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center; */

  .swiper {
    padding-top: 50px;
    padding-bottom: 50px;

    /* @media (min-width: ${({ theme }) => theme.tablet}) {
      padding-left: 5%;
    } */

    .swiper-button-prev,
    .swiper-button-next {
      color: ${({ theme }) => theme.primary};
      width: 10px;
      z-index: 10000;

      &::before {
        content: '';
        position: absolute;
        min-width: 30px;
        width: 5vw;
        max-width: 50px;
        height: 300px;
        background-color: transparent;
      }
    }
  }

  .swiper-next-btn {
    width: 20px;
    height: 20px;
    background-color: red;
  }

  .swiper .swiper-wrapper .swiper-slide {
    width: 75vw;
    height: 300px;
    transition: transform 0.3s linear;
    cursor: pointer;
    overflow: hidden;

    &.swiper-slide-active {
      transform: scale(1.05);

      @media (min-width: ${({ theme }) => theme.tablet}) {
        transform: scale(1.2);
      }
    }

    @media (min-width: ${({ theme }) => theme.tablet}) {
      width: 520px;
      height: 340px;
    }
  }

  /* @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 0 5%;
  } */

  @media (min-width: 1200px) {
    padding: 0;
  }
`;

const SlideWrapper = styled.div`
  transition: all 0.6s linear;
  position: relative;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const SlideName = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SecondGallery = ({ link }) => {
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [clickedIndex, setClickedIndex] = useState();
  const images = useStaticQuery(query);

  const thumbnails = [
    ['Kawalerka 32m2 w Kamienicy', KawalerkaWKamienicy],
    [undefined, <Iframe link={link} />],
    ['Nowoczesne mieszkanie w kamienicy', NowoczesnaKamienica],
  ];

  const handleClick = (e) => {
    setClickedIndex(e);
    if (e === 1) {
      setOpen1(true);
    } else if (e === 2) {
      setOpen2(true);
    }
  };

  const handleClose = (e) => {
    setClickedIndex(undefined);

    if (e === 1) {
      setOpen1(false);
    } else if (e === 2) {
      setOpen2(false);
    }
  };

  return (
    <Wrapper>
      <Swiper
        initialSlide={1}
        modules={[Navigation]}
        spaceBetween={20}
        breakpoints={{
          768: {
            spaceBetween: 100,
          },
        }}
        slidesPerView={'auto'}
        loop={true}
        centeredSlides
        navigation={true}
      >
        {[...thumbnails].map(([name, src], index) => (
          <SwiperSlide onClick={() => handleClick(index + 1)}>
            {name == undefined ? (
              <SlideWrapper className='slide-wrapper'>
                <Iframe link={link} />
              </SlideWrapper>
            ) : (
              <SlideWrapper className='slide-wrapper'>
                <img src={src} alt='Zdjęcie inwestycji' />
                <SlideName>
                  <Text style={{ textAlign: 'center', color: '#fff' }}>
                    {name}
                  </Text>
                </SlideName>
              </SlideWrapper>
            )}
          </SwiperSlide>
        ))}
      </Swiper>

      <Lightbox
        open={open1}
        close={() => handleClose(1)}
        slides={images.kawalerkaWKamienicy.edges.map((item, key) => ({
          src: item.node.publicURL,
        }))}
        plugins={[Slideshow, Thumbnails, Zoom]}
        thumbnails={{
          border: '0',
        }}
      />
      <Lightbox
        open={open2}
        close={() => handleClose(2)}
        slides={images.nowoczesnaKamienica.edges.map((item, key) => ({
          src: item.node.publicURL,
        }))}
        plugins={[Zoom]}
        thumbnails={{
          border: '0',
        }}
      />
    </Wrapper>
  );
};

const query = graphql`
  query SecondGalleryImages {
    kawalerkaWKamienicy: allFile(
      filter: { relativeDirectory: { eq: "investments/kawalerka-w-kamienicy" } }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          publicURL
        }
      }
    }
    nowoczesnaKamienica: allFile(
      filter: {
        relativeDirectory: {
          eq: "investments/nowoczesne-mieszkanie-w-kamienicy"
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`;

export default SecondGallery;
